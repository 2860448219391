<template>
  <div class="list">
    <template v-if="loading && book">
      <div class="list-book">
        <div class="list-book-cover">
          <van-image
            fit="contain"
            :src="
              book.coverPicUrl == ''
                ? 'https://53english.cdn.53online.cn/bookCover.png'
                : book.coverPicUrl
            "
          />
        </div>
        <div class="list-book-flex">
          <div class="title" v-html="book.bookName"></div>
          <div v-if="book.allResUrl" @click="tapPopup" class="down">
            下载整书资源
          </div>
          <div v-else class="down1"></div>
        </div>
      </div>

      <div class="list-item" v-if="list.length > 0">
        <dl v-for="item in list" :key="item.id" class="list-item-box">
          <div class="dt part" @click="item.show = !item.show">
            <template v-if="item.childrenList.length > 0">
              <em>{{ item.name }}</em
              ><i-up-c
                v-if="item.show"
                theme="outline"
                size="24"
                fill="#FF934A"
              />
              <i-down-c v-else theme="outline" size="24" fill="#FF934A" />
            </template>
            <template v-else>
              <router-link :to="{ name: 'list', query: { fid: item.id } }">
                <em>{{ item.name }}</em>
                <i-right-c theme="outline" size="24" fill="#FF934A" />
              </router-link>
            </template>
          </div>
          <item-lis v-if="item.show" :list="item.childrenList"></item-lis>
        </dl>
      </div>
    </template>
    <van-loading v-else vertical type="spinner">{{ loadingText }}</van-loading>
    <van-popup v-model="downShow" style="width: 90%" closeable>
      <div class="downpopup" v-if="loading && book">
        <div class="downpopup-title">
          {{ book.bookName }}
        </div>
        <div class="downpopup-bottom">
          <div class="downpopup-btn">
            <span v-if="isIos" @click="downFile(book.allResUrl)" class="down"
              >下载</span
            >
            <span class="down down2" v-if="isWxBrowser && !isIos"
              >点击右上角···选择默认浏览器打开</span
            >
            <template v-else>
              <span v-if="isAnd" @click="downFile(book.allResUrl)" class="down"
                >下载</span
              >
            </template>
          </div>
          <div class="downpopup-demo">
            <span v-if="isIos" class="down1" @click="isIosShow = true"
              >苹果手机下载后如何查看资源</span
            >
            <!-- <span v-if="isAnd" class="down1" @click="isAndShow = false"
              >安卓手机下载后如何查看资源</span
            > -->
          </div>
        </div>
        <div class="downpopup-mail">
          <van-field v-model="mail" placeholder="输入邮箱" />
          <van-button class="down" @click="tapMail">下载至邮箱</van-button>
        </div>
      </div>
    </van-popup>
    <van-image-preview v-model="isIosShow" :images="iosList" />
    <van-image-preview v-model="isAndShow" :images="andList" />
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import itemLis from "@/components/itemLis.vue";
export default {
  name: "list",
  components: { itemLis },
  data() {
    return {
      loading: false,
      loadingText: "加载中...",
      // fid:
      //   this.$route.query.fid == undefined
      //     ? "625690238756327424"
      //     : this.$route.query.fid,
      fid: this.$route.query.fid,
      list: [],
      book: null,
      downShow: this.$route.query.down ? this.$route.query.down : false,
      mail: "",
      isIos: false,
      isIosShow: false,
      iosList: [
        "https://53english.cdn.53online.cn/ios/1.png",
        "https://53english.cdn.53online.cn/ios/2.png",
        "https://53english.cdn.53online.cn/ios/3.png",
        "https://53english.cdn.53online.cn/ios/4.png",
        "https://53english.cdn.53online.cn/ios/5.png",
        "https://53english.cdn.53online.cn/ios/6.png",
        "https://53english.cdn.53online.cn/ios/7.png",
      ],
      isAnd: false,
      isAndShow: false,
      andList: [
        "https://img01.yzcdn.cn/vant/apple-1.jpg",
        "https://img01.yzcdn.cn/vant/apple-2.jpg",
      ],
      isWxBrowser: false,
    };
  },
  watch: {},
  mounted() {
    //
    this.getList();
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.isIos = /iphone|ipad|ipod/.test(userAgent);
    this.isAnd = /android/.test(userAgent);
    this.isWxBrowser = /micromessenger/.test(userAgent);
  },
  methods: {
    downFile(url) {
      window.location.href = url;
    },
    getList() {
      this.$api.res
        .getIndexList({ fid: this.fid })
        .then(({ tag, data, message }) => {
          if (tag == 1) {
            this.loading = true;
            this.book = data;
            const arr = [];
            data.catalogList.map((item) => {
              arr.push({ ...{ show: false }, ...item });
            });
            this.list = arr;
            console.log(this.list);
          } else {
            //获取数据失败
            Dialog.alert({ title: "获取数据失败", message: message }).then(
              () => {
                this.loadingText = "页面出现异常，请重新进入！";
                //点击事件
              }
            );
          }
        });
    },
    tapPopup() {
      this.downShow = !this.downShow;
      if (this.downShow == true && this.isWxBrowser) {
        let params = new URLSearchParams(window.location.search);
        let url = window.location.href;
        let down = params.get("down");
        if (down != "true") {
          url = window.location.href + "&down=true";
        }
        window.history.replaceState(
          {
            path: url,
          },
          "",
          url
        );
      }
    },
    tapMail() {
      if (this.isValidEmail(this.mail)) {
        let params = {
          BookName: this.book.bookName,
          AllResUrl: this.book.allResUrl,
          Email: this.mail,
        };
        this.$api.res.sendEmail(params).then(({ tag }) => {
          if (tag == 1) {
            Toast("邮件发送成功");
            this.downShow = false;
          } else {
            Toast("邮件发送失败");
          }
        });
      } else {
        Toast("请输入正确的邮箱");
      }
    },
    isValidEmail(email) {
      // 使用正则表达式判断邮箱格式
      var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailRegex.test(email);
    },
  },
};
</script>
